import React, { useEffect, useState } from "react"
import ReactDOM from "react-dom"
import Layout from "../../../components/layout"
import Helmet from "react-helmet"

const ENPSCalculator = () => {
  const [detractors, setDetractors] = useState(Array(7).fill(""))
  const [passives, setPassives] = useState(Array(2).fill(""))
  const [promoters, setPromoters] = useState(Array(2).fill(""))
  const [scale, setScale] = useState(10)

  const handleDetractorChange = (index, value) => {
    const updatedDetractors = [...detractors]
    updatedDetractors[index] = Number(value) || ""
    console.log("updatedDetractors", updatedDetractors)
    setDetractors(updatedDetractors)
  }

  const handlePassiveChange = (index, value) => {
    const updatedPassives = [...passives]
    updatedPassives[index] = Number(value) || ""
    setPassives(updatedPassives)
  }

  const handlePromoterChange = (index, value) => {
    const updatedPromoters = [...promoters]
    updatedPromoters[index] = Number(value) || ""
    setPromoters(updatedPromoters)
  }

  const calculateENPS = () => {
    const detractorScore = detractors.reduce(
      (acc, value) => acc + Number(value || 0),
      0
    )
    const passiveScore = passives.reduce(
      (acc, value) => acc + Number(value || 0),
      0
    )
    const promoterScore = promoters.reduce(
      (acc, value) => acc + Number(value || 0),
      0
    )
    const totalResponses = detractorScore + passiveScore + promoterScore
    console.log(totalResponses, "totalResponses")
    const enps = (
      ((promoterScore - detractorScore) / totalResponses) *
      100
    ).toFixed(2)
    const detractorsPercentage = (
      (detractorScore / totalResponses) *
      100
    ).toFixed(2)
    const promotersPercentage = (
      (promoterScore / totalResponses) *
      100
    ).toFixed(2)

    if (totalResponses === 0) {
      return {
        enps: 0,
        detractorsPercentage: 0,
        promotersPercentage: 0,
      }
    }
    return { enps, detractorsPercentage, promotersPercentage }
  }

  const handleScaleChange = e => {
    const newScale = Number(e.target.value)
    setScale(newScale)
  }
  console.log("Scale", scale)

  useEffect(() => {
    if (scale === 5) {
      setDetractors(Array(4).fill(""))
      setPassives(Array(1).fill(""))
      setPromoters(Array(1).fill(""))
    } else {
      setDetractors(Array(7).fill(""))
      setPassives(Array(2).fill(""))
      setPromoters(Array(2).fill(""))
    }
  }, [scale])

  function renderCalculator() {
    const detractorRange = scale === 10 ? [0, 1, 2, 3, 4, 5, 6] : [0, 1, 2, 3]
    const passiveRange = scale === 10 ? [7, 8] : [4]
    const promoterRange = scale === 10 ? [9, 10] : [5]
    return (
      <>
        <div className=" flex flex-col items-center bg-purple-100 py-8 px-10  xl:rounded-xl">
          <div className="text-center">
            Based on your survey, Input the number of respondents in the boxes
            for each given score {scale == 10 ? "0-10" : "0-5"}
          </div>
          <div className="flex flex-col md:flex-row  gap-x-4 mt-10">
            <div>
              <div className="flex flex-wrap justify-center gap-x-4">
                {detractors.map((value, index) => (
                  <div key={index} className="flex flex-col items-center">
                    <div className="flex p-2 bg-white rounded-md shadow-md">
                      <input
                        key={index}
                        type="number"
                        value={value}
                        placeholder="0"
                        min={0}
                        onChange={e =>
                          handleDetractorChange(index, e.target.value)
                        }
                        className="text-center bg-lightPink placeholder-gray-50 w-16 h-16 border-0"
                      />
                    </div>
                    <div className="mt-3">{detractorRange[index]}</div>
                  </div>
                ))}
              </div>
              <div className="w-full h-2 bg-lightPink mt-3 rounded-md" />
              <h3 className="mt-3 text-center mb-20 md:mb-0">Detractors </h3>
            </div>
            <div className="text-center">
              <div className="flex flex-wrap justify-center gap-x-4">
                {passives.map((value, index) => (
                  <div key={index} className="flex flex-col items-center">
                    <div className="flex p-2 bg-white rounded-md shadow-md">
                      <input
                        key={index}
                        type="number"
                        placeholder="0"
                        min={0}
                        value={value}
                        onChange={e =>
                          handlePassiveChange(index, e.target.value)
                        }
                        className="text-center bg-lightblue placeholder-gray-50 w-16 h-16 border-0"
                      />
                    </div>
                    <div className="mt-3">{passiveRange[index]}</div>
                  </div>
                ))}
              </div>
              <div className="w-full h-2 bg-lightblue mt-3 rounded-md" />
              <h3 className="mt-3 text-center mb-20 md:mb-0"> Passives </h3>
            </div>
            <div className="text-center">
              <div className="flex flex-wrap justify-center gap-x-4">
                {promoters.map((value, index) => (
                  <div key={index} className="flex flex-col items-center">
                    <div className="flex p-2 bg-white rounded-md shadow-md">
                      <input
                        key={index}
                        type="number"
                        placeholder="0"
                        min={0}
                        value={value}
                        onChange={e =>
                          handlePromoterChange(index, e.target.value)
                        }
                        className="text-center bg-lightgreen placeholder-gray-50 w-16 h-16 border-0"
                      />
                    </div>
                    <div className="mt-3">{promoterRange[index]}</div>
                  </div>
                ))}
              </div>
              <div className="w-full h-2 bg-lightgreen mt-3 rounded-md" />
              <h3 className="mt-3 text-center">Promoters</h3>
            </div>
          </div>
        </div>
      </>
    )
  }

  return (
    <>
      <Helmet>
        <title>Employee Net Promoter Score (eNPS) Calculator</title>
        <meta
          name="description"
          content="Use our eNPS Calculator to quickly gauge employee loyalty and satisfaction, helping you enhance your company's work environment."
        />
        <meta
          property="twitter:title"
          content="Employee Net Promoter Score (eNPS) Calculator | Vantage Circle"
        />
        <meta name="robots" content="index" />
        <meta
          property="twitter:description"
          content="Use our eNPS Calculator to quickly gauge employee loyalty and satisfaction, helping you enhance your company's work environment."
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="
https://www.vantagecircle.com/calculators/employee-net-promoter-score-enps-calculator/"
        />
        <meta property="og:site_name" content="Vantage Circle" />
        <meta
          property="og:title"
          content="Employee Net Promoter Score (eNPS) Calculator | Vantage Circle"
        />
        <meta
          property="og:description"
          content="Use our eNPS Calculator to quickly gauge employee loyalty and satisfaction, helping you enhance your company's work environment."
        />
        <link
          rel="canonical"
          href="
https://www.vantagecircle.com/calculators/employee-net-promoter-score-enps-calculator/"
        ></link>
      </Helmet>
      <Layout>
        {" "}
        <section
          style={{
            backgroundImage:
              'url("https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2024/04/eNPS-Page-Header-2.png")',
            backgroundPosition: "center",
            backgroundSize: "cover",
          }}
          class="text-center bg-indigo-100"
        >
          <div class="max-w-7xl mx-auto py-20">
            <h1 class=" text-2xl md:text-5xl text-white mb-12 px-10">
              Employee Net Promoter Score (eNPS) Calculator
            </h1>
            <p class="text-white text-justify md:text-center px-10">
              Efficiently gauge your organization's employee loyalty and
              satisfaction with our intuitive eNPS Calculator, designed to
              measure and analyze the net promoter score among your workforce,
              helping you identify areas for improvement and fostering a
              positive workplace culture.
            </p>
          </div>
        </section>
        <div className="max-w-7xl mx-auto my-10">
          <div className=" text-center flex flex-col items-center mt-20">
            <label htmlFor="select-scale">Select your Scale</label>
            <select
              id="select-scale"
              value={scale}
              onChange={handleScaleChange}
              className="w-6/12 md:w-2/12 my-5 text-center border-0 bg-purple-100 rounded-2xl"
            >
              <option value={10}>0-10 scale</option>
              <option value={5}>0-5 scale</option>
            </select>
          </div>
          {renderCalculator()}
          <div className="flex flex-col md:flex-row mt-5 gap-x-6">
            <div className="flex bg-purple-100 w-full md:w-7/12 py-8 xl:rounded-xl">
              <div className="w-1/2 flex flex-col items-center space-y-4">
                <img
                  width="80"
                  height="80"
                  src="https://img.icons8.com/ios/100/happy--v1.png"
                  alt="happy--v1"
                />
                <p className="text-xl">Promoters</p>{" "}
                <p className="border border-greenD2 rounded-2xl text-xl font-semibold text-greenD2 px-10 md:px-20 py-2 bg-greenlight50">
                  {calculateENPS().promotersPercentage} %
                </p>{" "}
              </div>
              <div className="w-1/2 flex flex-col items-center space-y-4">
                <img
                  width="80"
                  height="80"
                  src="https://img.icons8.com/ios/100/sad.png"
                  alt="sad"
                />
                <p className="text-xl">Detractors</p>{" "}
                <p className="border border-lightPink rounded-2xl text-xl font-semibold text-red px-10 md:px-20 py-2 bg-pink50">
                  {calculateENPS().detractorsPercentage} %
                </p>{" "}
              </div>
            </div>
            <div className="w-full md:w-5/12 flex flex-col bg-purple-100 py-8 xl:rounded-xl">
              <div className=" text-center w-4/6 mx-auto py-5 h-full rounded-xl flex flex-col items-center justify-center space-y-3">
                <div className="text-3xl">Your eNPS Score</div>
                <div className="bg-indigo-200 text-white text-5xl font-normal px-10 py-5 rounded-md">
                  {calculateENPS().enps} %
                </div>
              </div>
            </div>
          </div>
        </div>
        <section className="px-5 w-full flex justify-center py-10 xl:py-0">
          <div className="md:w-7/12 max-w-7xl">
            <h2 className="text-3xl pb-5 md:pb-10 pt-5 md:pt-10">
              About this eNPS calculator
            </h2>
            <p className="text-indigo-100">
              Curated for HR professionals, this Employee Net Promoter Score
              (eNPS) calculator offers a straightforward assessment of employee
              satisfaction and loyalty. Simply input your survey data and
              instantly receive your eNPS.
            </p>
            <p className="text-indigo-100">
              With a focus on accuracy and ease, our tool streamlines the eNPS
              process, enabling HR to derive meaningful insights from employee
              feedback quickly. In addition, we have provided 2 scales to
              calculate the same. Depending on your organization's scale, you
              can choose the one that fits.
            </p>

            <h2 className="text-3xl pb-5 md:pb-10 pt-5 md:pt-10">
              What is Employee Net Promoter Score or eNPS?
            </h2>
            <p className="text-indigo-100">
              Employee Net Promoter Score (eNPS) is a pivotal metric that gauges
              employee engagement and loyalty. While the traditional Net
              Promoter Score (NPS) measures customer loyalty, eNPS shifts the
              focus inward, spotlighting the sentiments of an organization's
              most valuable asset: its employees.
            </p>

            <h2 className="text-3xl pb-5 md:pb-10 pt-5 md:pt-10">
              What is the importance of calculating the eNPS Score?
            </h2>
            <p className="text-indigo-100">
              Comprehensive Satisfaction Measure: The score evaluates overall
              employee satisfaction and workplace contentment.
            </p>

            <ol style={{ listStyleType: "auto" }}>
              <li>
                <strong>Effective Feedback Analysis:</strong> Companies can
                pinpoint thriving areas and areas needing improvement by
                assessing employee feedback.
              </li>
              <li>
                <strong>Enhanced Employee Engagement:</strong> eNPS serves as a
                platform for valued employee-company dialogue, boosting
                motivation and involvement.
              </li>
              <li>
                <strong>Showcasing Positive Work Environment:</strong> Using
                eNPS as a metric helps companies display their positive work
                environment, attracting top talent.
              </li>
              <li>
                <strong>Reduced Turnover and Resources:</strong> Keeping
                employees happy and engaged through eNPS decreases turnover,
                saving time and resources on continuous training.
              </li>
              <li>
                <strong>Facilitated Communication:</strong> The score fosters
                open dialogue between employees and management, a crucial aspect
                of a thriving workplace.
              </li>
              <li>
                <strong>Improved Satisfaction for Productivity:</strong> Higher
                employee satisfaction via eNPS leads to increased productivity,
                teamwork, and profitability.
              </li>
              <li>
                <strong>Cultural Measurement and Enhancement:</strong> eNPS
                helps measure and enhance the company's culture, vital in
                retaining top talent.
              </li>
              <li>
                <strong>Long-term Employee Retention:</strong> Creating a
                fulfilling work environment through eNPS aids in retaining
                valued employees for the long term.
              </li>
              <li>
                <strong>Mutually Beneficial Success:</strong> Happy employees
                and a thriving company make eNPS an essential tool for overall
                business success.
              </li>
            </ol>

            <h2 className="text-3xl pb-5 md:pb-10 pt-5 md:pt-10">
              How to calculate Employee Net Promoter Score (eNPS)?
            </h2>
            <p className="text-indigo-100">
              In an eNPS-based survey, the questions are numbered on a scale
              instead of a definitive “yes” or “no.” The scale usually ranges
              from either 0-10 or 1-5, depending on the survey.
            </p>
            <p className="text-indigo-100">
              Each survey respondent is then classified into either of these
              three categories:
            </p>
            <ol className="pb-10">
              <li>
                <strong>Promoter:</strong> Employees who respond to the survey
                with 9-10 in a 0-10 scale or 4-5 in a 1-5 scale.
              </li>
              <li>
                <strong>Detractor:</strong> Employees who respond to the survey
                with 0-6 in a 0-10 scale or 1-2 in a 1-5 scale.
              </li>
              <li>
                <strong>Passives:</strong> Employees who respond to the survey
                with 7-8 in a 0-10 scale or 3 in a 1-5 scale.
              </li>
            </ol>

            <div
              style={{
                textAlign: "center",
                margin: "auto",
                padding: "20px",
                border: "1px solid #ccc",
                borderRadius: "8px",
                boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
              }}
            >
              <div
                style={{
                  padding: "10px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <span style={{ marginRight: "10px" }}>
                  Employee Net Promoter Score (eNPS) =
                </span>
                <div style={{ textAlign: "center" }}>
                  <div
                    style={{
                      borderBottom: "2px solid #000",
                      paddingBottom: "5px",
                    }}
                  >
                    (Number of Promoters - Number of Detractors)
                  </div>
                  <div style={{ paddingTop: "5px" }}>
                    Total Number of Survey Respondents
                  </div>
                </div>
                <span style={{ marginLeft: "5px" }}>× 100</span>
              </div>
            </div>

            <h2 class="text-3xl pb-5 md:pb-10 pt-5 md:pt-10">
              Explaining the scales '1-10' and '1-5': Benefit and uses
            </h2>

            <p class="text-indigo-100">
              The choice between the 1-10 and 1-5 scales for eNPS (Employee Net
              Promoter Score) calculations can impact the granularity and
              interpretation of employee feedback. Each scale has its benefits
              and uses, as outlined below:
            </p>

            <h3 class="text-xl pb-5 pt-5 text-indigo-100 font-bold">
              1-10 Scale
            </h3>

            <div class="xl:pl-10">
              <p class=" font-semibold pb-2 pt-5 text-indigo-100">Benefits:</p>

              <div class="xl:pl-10">
                <p class="text-indigo-100">
                  <strong>Greater Precision:</strong> The wider range allows for
                  more nuanced feedback, helping to differentiate between
                  varying levels of employee satisfaction and engagement.
                </p>

                <p class="text-indigo-100">
                  <strong>Detailed Segmentation:</strong> With more scoring
                  options, companies can better segment employees into distinct
                  categories (Promoters, Passives, and Detractors) and
                  understand the intensity of their feelings.
                </p>
                <p class="text-indigo-100">
                  <strong>Standard Comparison:</strong> This scale is consistent
                  with the traditional Net Promoter Score (NPS) used for
                  customer satisfaction, making it easier to align and compare
                  external customer satisfaction with internal employee
                  engagement.
                </p>
              </div>
            </div>

            <div class="xl:pl-10">
              <h4 class=" font-semibold pb-2 pt-5 text-indigo-100">Uses:</h4>

              <div class="xl:pl-10">
                <p class="text-indigo-100">
                  <strong>Deep-Dive Analysis:</strong> Ideal for detailed
                  analysis and when you want to capture subtle differences in
                  employee sentiment. It's beneficial for large organizations
                  where small differences can indicate significant trends.
                </p>
                <p class="text-indigo-100">
                  <strong>Longitudinal Tracking:</strong> Useful for tracking
                  changes over time, allowing companies to detect even slight
                  shifts in employee sentiment.
                </p>
                <p class="text-indigo-100">
                  <strong>Benchmarking:</strong> Companies can benchmark their
                  results against industry standards or competitors who use the
                  same scale, providing a clearer context for interpreting their
                  scores.
                </p>
              </div>
            </div>

            <h3 class="text-xl pb-5 pt-5 text-indigo-100 font-bold">
              1-5 Scale
            </h3>

            <div class="xl:pl-10">
              <p class=" font-semibold pb-2 pt-5 text-indigo-100">Benefits:</p>

              <div class="xl:pl-10">
                <p class="text-indigo-100">
                  <strong>Simplicity and Clarity:</strong> Easier for
                  respondents to understand and choose their level of
                  satisfaction, potentially leading to higher response rates.
                </p>

                <p class="text-indigo-100">
                  <strong>Quick Analysis:</strong> With fewer categories, it's
                  faster to analyze and interpret the overall sentiment of the
                  workforce.
                </p>

                <p class="text-indigo-100">
                  <strong>Reduced Ambiguity:</strong> Less risk of respondents
                  misinterpreting the scale's meaning, leading to more accurate
                  data.
                </p>
              </div>
            </div>

            <div class="xl:pl-10">
              <h4 class=" font-semibold pb-2 pt-5 text-indigo-100">Uses:</h4>

              <div class="xl:pl-10">
                <p class="text-indigo-100">
                  <strong>Fast Decision-Making:</strong> Suitable for
                  organizations that require quick insights to make immediate
                  improvements or changes.
                </p>

                <p class="text-indigo-100">
                  <strong>Small to Medium-Sized Enterprises (SMEs):</strong>{" "}
                  Ideal for smaller companies where fewer employees make each
                  response more impactful, and clear distinctions are easier to
                  manage.
                </p>

                <p class="text-indigo-100">
                  <strong>High-Level Overview:</strong> When an organization
                  seeks a straightforward measure of employee sentiment without
                  the need for detailed segmentation.
                </p>
              </div>
            </div>

            <p class="text-indigo-100">
              In summary, the 1-10 scale is beneficial for organizations looking
              for detailed feedback and nuanced analysis, while the 1-5 scale is
              better suited for simplicity, clarity, and quick decision-making
              processes. The choice between these scales depends on the specific
              needs, size, and goals of the organization regarding employee
              feedback and engagement.
            </p>
          </div>
        </section>
        <section className="px-5 w-full flex justify-center py-10 xl:py-10 mb:py-10">
          <a
            className="md:w-7/12 max-w-7xl mx-auto"
            href="https://www.vantagecircle.com/request-demo/?utm_source=calculator/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className="rounded-xl"
              src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2024/04/VC-AD-Banner-3.jpg"
              alt="Advertisement"
            />
          </a>
        </section>
      </Layout>
    </>
  )
}

export default ENPSCalculator
